<template>
  <div>
    <the-breadcrumbs :custom-breadcrumbs="breadcrumbs"></the-breadcrumbs>
    <page-content :page-title="collection.parent.name">
      <template #actions>
        <el-button
          v-if="$store.getters['auth/isSuperAdmin']"
          @click="dialogImportVisible = true"
        >
          CSV importieren
        </el-button>
        <el-button type="primary" icon="fal fa-plus" @click="newInstitution">
          Neue Einrichtung
        </el-button>
      </template>
      <el-table
        v-loading="loading"
        :data="institutions"
        stripe
        :default-sort="{ prop: 'name', order: 'ascending' }"
      >
        <el-table-column prop="name" label="Name" sortable></el-table-column>
        <el-table-column
          prop="subtitle"
          label="Untertitel"
          sortable
        ></el-table-column>
        <el-table-column
          prop="address"
          label="Adresse"
          sortable
        ></el-table-column>
        <el-table-column prop="city" label="Ort" sortable></el-table-column>
        <el-table-column label="Aktionen" width="160">
          <template #default="scope">
            <el-button
              icon="fal fa-edit"
              size="mini"
              @click="handleEdit(scope.row)"
            >
              Bearbeiten
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-content>

    <el-dialog title="CSV importieren" :visible.sync="dialogImportVisible">
      <institutions-import-form
        :collection-id="collection.id"
        @dialog-closed="dialogImportVisible = false"
      ></institutions-import-form>
    </el-dialog>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import InstitutionsImportForm from "@/components/forms/InstitutionsImportForm"
import InstitutionCollectionsRepository from "@/repositories/institution_collections_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Einrichtungssammlung"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    InstitutionsImportForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      dialogImportVisible: false,
      loading: false,
      collection: {
        parent: {
          name: ""
        }
      },
      institutions: []
    }
  },
  computed: {
    breadcrumbs() {
      let crumbs = [
        {
          path: "/guide",
          text: "Wissenswertes"
        }
      ]
      crumbs = crumbs.concat(this.getItemParents(this.collection).reverse())
      crumbs.pop()
      return crumbs
    }
  },
  async created() {
    this.loading = true

    try {
      this.collection = await InstitutionCollectionsRepository.get(
        this.$route.params.id
      )
      this.institutions = this.collection.institutions
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    handleEdit(institution) {
      this.$router.push({
        name: "EditInstitutionPage",
        params: {
          id: institution.id
        },
        query: {
          institution_collection_id: this.collection.id,
          institution_collection_name: this.collection.parent.name,
          redirect: this.$route.query.redirect || this.$route.fullPath
        }
      })
    },
    newInstitution() {
      this.$router.push({
        name: "NewInstitutionPage",
        query: {
          institution_collection_id: this.collection.id,
          institution_collection_name: this.collection.parent.name,
          redirect: this.$route.query.redirect || this.$route.fullPath
        }
      })
    },
    getItemParents(item) {
      let parents = []
      if (item.parent) {
        parents.push({
          path: `/guide_items/${item.parent.id}`,
          text: item.parent.name
        })
        if (item.parent.parent) {
          parents = parents.concat(this.getItemParents(item.parent))
        }
      }
      return parents
    }
  }
}
</script>
