import Repository from "@/repositories/api.js"
const resource = "/admin/institution_collections"

export default {
  async get(collectionID) {
    let response = await Repository.get(`${resource}/${collectionID}`)
    return response.data.institution_collection
  },
  async create(collectionData) {
    let response = await Repository.post(`${resource}`, {
      institution_collection: collectionData
    })
    return response.data
  },
  async update(collectionID, collectionData) {
    let response = await Repository.patch(`${resource}/${collectionID}`, {
      institution_collection: collectionData
    })
    return response.data
  },
  async destroy(collectionID) {
    let response = await Repository.delete(`${resource}/${collectionID}`)
    return response.data
  },
  async import(collectionID, file) {
    const formData = new FormData()
    formData.append(`file`, file)
    formData.append(`id`, collectionID)

    let response = await Repository.patch(
      `${resource}/${collectionID}/import`,
      formData
    )
    return response.data
  }
}
