<template>
  <el-form ref="importForm" :model="importData" label-position="top">
    <file-upload-field
      current-label="Aktuelle Datei"
      label="Datei auswählen"
      @file-chosen="setFile"
    />
    <div>
      <el-button type="primary" :loading="saving" @click="checkForm">
        Importieren
      </el-button>
      <el-button @click="closeDialog">
        Abbrechen
      </el-button>
    </div>
  </el-form>
</template>

<script>
import FileUploadField from "@/components/FileUploadField"
import InstitutionCollectionsRepository from "@/repositories/institution_collections_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    FileUploadField
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    collectionId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      importData: {
        file: null
      },
      saving: false
    }
  },
  methods: {
    setFile(file) {
      this.importData.file = file
    },
    closeDialog() {
      this.$emit("dialog-closed")
    },
    checkForm() {
      this.$refs["importForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        }
      })
    },
    async submitForm() {
      this.saving = true

      try {
        await InstitutionCollectionsRepository.import(
          this.collectionId,
          this.importData.file
        )
        this.saving = false
        this.$emit("dialog-closed")
      } catch (error) {
        this.saving = false
        this.$emit("dialog-closed")
        this.handleApiError(error, "Import Fehler")
      }
    }
  }
}
</script>
